module.exports = {
  site: {
    title: `Queenstown Hotel | Queenstown Accommodation | mi-pad Queenstown`,
    description: `mi-pad is an award-winning, smart, modern, sophisticated and luxury hotel in the heart of downtown central Queenstown on the shores of world-renowned Lake Whakatipu.
`, // Used in the meta description
    siteUrl: `https://www.mipadhotels.com`, // No trailing slash
    image: `site-image.jpg`, // Relative to the folder `./static/`
  },
  manifest: {
    shortName: `mi-pad`, // Under 12 characters.
    bgColor: `#ffffff`,
    themeColor: `#0077c7`,
    icon: `icon.png`, // Relative to the folder `./src/images/`
  },
  prismic: {
    repository: `mipad001`,
  },
  google: {
    tagManager: {
      tagManagerId: `GTM-NW2XGXG`,
    },
  },
}
