import React, { useEffect } from 'react'
import Layout from 'components/Layout'
import { navigate } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic'
import generateUrl from 'utils/generateUrl'
import config from 'config/site'
import { useNavigationQuery } from 'utils/navigation'

import heroPlaceholder from 'images/bg-mountains-a.jpg'

const PreviewPage = ({ location }) => {
  const {
    prismicNavigation: {
      data: { body: navigation },
    },
  } = useNavigationQuery()

  const pathResolver = () => doc => {
    switch (doc.type) {
      case `experiences`:
        return `experience/${doc.uid}`
      case `home`:
        return `/`
      default:
        return generateUrl(doc.uid, navigation) || doc.uid || `/`
    }
  }

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: config.prismic.repository,
    pathResolver,
  })

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData
      navigate(path)
    }
  }, [previewData, path])

  return (
    <Layout metaTitle="Preview" metaDescription="Preview" location={location}>
      <img
        className="hidden xl:block w-full"
        src={heroPlaceholder}
        alt="Preview page hero"
      />
      <div className="py-15 md:py-32 pb-40 bg-cream">
        <div className="container">
          <div className="text-center">
            <h1 className="text-5xl md:text-6xl xl:text-9xl leading-none mb-10 tracking-wide">
              Loading
            </h1>
            <div className="leading-snug">
              Please wait while your preview loads...
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PreviewPage
